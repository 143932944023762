// jQuery
window.$ = window.jQuery = require('jquery');

// OWL Carousel
require('owl.carousel');

// Menu
require('hoverintent');
require('superfish');

// Slick Slider
require('slick-carousel');

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
